import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer_de'
import * as styles from '../../components/css/privacypolicy.module.css'
import Container from '../../components/container'
import SEO from '../../components/seo'

const privacypolicy = () => {
    return (
        <div className={styles.main}>
            <SEO title="Privacy Police" description="Privacy Police App Deutsch" />
            {/* <Header captionText="Privacy Police App" /> */}
            <Header />
                <Container>
                    <h2>Privacy Police App</h2>
                    {/* <p>
                        Diese Privacy Police bezieht sich auf die App Universal Time Complication.
                    </p> */}
                    {/* <p>
                        Die App speichert, über die Einstellungen hinaus, keine Daten auf dem Gerät.
                        Die Einstellungen werden unverschlüsselt auf der Apple Watch abgelegt.
                        In den Einstellungen sind keine persönlichen Daten enthalten. Es wird lediglich die eingestellte Abweichung in Stunden und Minuten, 
                        der Modus (UTC oder local Time), der Zeitpunkt der nächsten geplanten Aktualisierung der Complication und der Zeitpunkt der letzten Hintergrundaktualisierung.
                        Die Einstellungen dienen ausschließlich der Nutzung der App.
                        Es werde keine Daten über das Nutzerverhalten gespeichert oder ausgewertet.
                        Die Einstellungen werden unwiderruflich gelöscht, sobald die App deinstalliert wird. Eine Speicherung der Einstellungen in der iCloud ist nicht vorgesehen.
                    </p> */}
                    {/* <p>
                        <div>Ich bin ein privater Entwickler der seine Apps ausschließlich mit der Motivation der eigenen Nutzung entwickelt.</div>
                    </p> */}
                    <p>
                        <div>Daten werden von der App Universal Time Complication nicht erfasst, gespeichert oder ausgewertet.</div>
                        <div>Eventuell werden anonyme Nutzerstatistiken von Apple zur Verfügung gestellt, wenn ihr dem zugestimmt habt.</div>
                        <div>Einstellungen der App werden ausschließlich lokal auf dem Gerät gespeichert.</div>
                        <div>Beim Löschen der App werden alle lokal gespeicherten Einstellungen unwiderruflich gelöscht.</div>
                    </p>
                    <h2>Kontakt</h2>
                    <p>
                        <div>Support: hilfe (at) universaltimecomplication (dot) de</div>
                        <div>Feedback: feedback (at) universaltimecomplication (dot) de</div>
                    </p>
                </Container>
            <Footer />
        </div>
    )
}

export default privacypolicy
